import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Vienna from "../../images/vienna.jpg"
import Burger from "../../images/burger.jpg"
import Limonade from "../../images/limonade.jpg"

const WienCateringPage = () => (
  <Layout>
    <SEO title="Catering in Wien • Private Feiern • Firmenfeiern • Events" />

    <div
      className="site-blocks-cover inner-page overlay catering-cover"
      style={{ backgroundImage: `url(${Vienna})` }}
    >
      <div className="row align-items-center justify-content-center mx-2">
        <div className="col-12 text-center">
          <h1 className="text-uppercase text-center">
            <span>Catering in Wien</span>
          </h1>
          <span className="caption bg-primary p-2 text-white text-center">
            Selbstgemacht, Frisch und Regional
          </span>
        </div>
      </div>
    </div>

    <div className="yellow-lines"></div>

    <div className="site-half mt-15">
      <div className="img-bg-1" style={{ backgroundImage: `url(${Burger})` }}></div>
      <div className="container">
        <div className="row no-gutters align-items-stretch">
          <div className="col-lg-5 ml-lg-auto py-5 py-5 px-5 px-md-0">
            <span className="caption d-block mb-2 font-secondary font-weight-bold">
              100% österreichisches Rindfleisch
            </span>
            <h2 className="site-section-heading text-uppercase font-secondary mb-5">
              Nicht nur Burger
            </h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Necessitatibus aliquid eius facilis voluptatem eligendi magnam
              accusamus vel commodi asperiores sint rem reprehenderit nobis
              nesciunt veniam qui fugit doloremque numquam quod.
            </p>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Consectetur tempora distinctio ipsam nesciunt recusandae
              repellendus asperiores amet.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="site-half block mb-5">
      <div
        className="img-bg-1 right"
        style={{ backgroundImage: `url(${Limonade})` }}
      ></div>
      <div className="container">
        <div className="row no-gutters align-items-stretch">
          <div className="col-lg-5 mr-lg-auto py-5 px-5 px-md-0">
            <span className="caption d-block mb-2 font-secondary font-weight-bold">
              Ein Catering nach Ihren{" "}
            </span>
            <h2 className="site-section-heading text-uppercase font-secondary mb-5">
              Nicht nur Burger
            </h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Necessitatibus aliquid eius facilis voluptatem eligendi magnam
              accusamus vel commodi asperiores sint rem reprehenderit nobis
              nesciunt veniam qui fugit doloremque numquam quod.
            </p>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Consectetur tempora distinctio ipsam nesciunt recusandae
              repellendus asperiores amet.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="py-5 bg-primary contact-box">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-8 text-center mb-3 mb-md-0">
            <h2 className="text-uppercase text-white mb-4" data-aos="fade-up">
              Hast du Interresse an Helmart's Catering?
            </h2>
            <div className="text-center text-uppercase mb-4">
              <span className="bg-white p-2 text-primary caption">
                Wir stellen Dir gerne ein individuelles
              </span>
              <br />
              <span className="bg-white p-2 text-primary caption">
                Angebot passend für Deine Feier zusammen.
              </span>
            </div>
            <Link className="btn btn-ghost" to="/kontakt/">
              Schick uns eine Anfrage
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default WienCateringPage
